<template>
	<div class="sld_addinvoice_list">
		<div class="container">
			<h3 @click="goBack">{{ L['开票'] }}</h3>

			<div class="bd30 flex-col">
				<div class="group11 flex-row">

					<div class="layer8 flex-col active">
						<span class="word25">1</span>
					</div>
					<div class="layer7 flex-col active"></div>
					<div class="layer8 flex-col" :class="{ active: step > 1 }">
						<span class="word25">2</span>
					</div>
				</div>
				<div class="group12 flex-row" :class="{ active: step >= 2 }">
					<span class="info15 info16 active">{{L['选择待开票数据']}}</span>
					<span class="info15" :class="{ active: step >= 2 }">{{L['确定发票信息和地址']}}</span>
				</div>
				<!-- 供应商列表 -->

				<template v-if="step == 1">
					<!-- 填写信息 -->
					<div class="group17 flex-row">
						<span class="txt11">{{L['订单编号：']}}</span>
						<div class="group14 flex-col">
							<el-input v-model="searchVal.orderSn" :placeholder="L['请输入订单编号']" />
						</div>
						<span class="word27">{{L['采购单号：']}}</span>
						<div class="group14 flex-col">
							<el-input v-model="searchVal.purchaseSn" :placeholder="L['请输入采购单号']" />
						</div>


						<button class="box6 flex-col" @click="getList">
							<span class="txt15">{{L['搜索']}}</span>
						</button>
						<div class="box7 flex-col" @click="initSearch">
							<span class="word34">{{L['重置']}}</span>
						</div>

					</div>
					<div class="group18 flex-row">
						<span class="info18">{{L['支付时间：']}}</span>
						<div class="box5 flex-col">
							<div class="bd31 flex-row">
								<el-date-picker v-model="selectTime" type="daterange" range-separator="~"
									:start-placeholder="L['开始日期']" :end-placeholder="L['结束日期']" value-format="YYYY-MM-DD">
								</el-date-picker>
							</div>
						</div>

					</div>


					<div class="group19 flex-row">
						<el-checkbox v-model="checkAllVal" @change="checkAll">{{L['全选']}}</el-checkbox>
						<div class="wrap2">
							<span class="word35">{{L['可开发票金额：']}}</span>
							<span class="word36">{{L['¥']}}{{ sumMoney }}</span>
						</div>
					</div>
					<div class="product_list"
						:style="incoiceList.data.length > 0 ? 'overflow-x:auto;' : 'overflow-x:hidden;'">
						<div class="layer10 flex-col">
							<div class="mod2 flex-row">
								<span class="word38 word40">{{L['订单编号']}}</span>
								<span class="word38 word41">{{L['采购单号']}}</span>
								<span class="word38 word39">{{L['供应商']}}</span>
								<span class="word38 info20">{{L['订单金额']}}</span>
								<span class="word38 txt17">{{L['支付时间']}}</span>
							</div>
						</div>
						<template v-if="incoiceList.data.length > 0">
							<template v-for="(item, index) in incoiceList.data" :key="index">
								<div class="layer14 flex-col">
									<div class="box8 flex-row">
										<el-checkbox class="mod5" v-model="item.isCheck"
											@change="checkSingle(item)">
										</el-checkbox>
										<span class="info25 word61">{{ item.orderSn }}</span>

										<span class="info25 info27">{{
											item.purchaseSn ? item.purchaseSn :
											'--'
										}}</span>

										<span class="info25 info26">{{ item.storeName }}</span>

										<span class="word64">￥{{ item.orderAmount }}</span>

										<span class="info25 word62">{{ item.payTime }}</span>



									</div>
								</div>
							</template>
						</template>
						<template v-else>
							<SldCommonEmpty totalWidth="1003" :tip="L['暂无待开票数据']" />
						</template>
					</div>
					<el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
						:page-size="pageData.pageSize" layout="sizes, prev, pager, next, jumper"
						:total="pageData.total" :hide-on-single-page="true" class="flex_row_end_center"
						v-if="incoiceList.data.length > 0">
					</el-pagination>
					<div class="group20 flex_row_center_center">
						<div class="bd32 active flex-col" @click="backStep" v-if="step > 1"
							style="margin-right:20px">
							<span class="word37">{{L['上一步']}}</span>
						</div>
						<div class="bd32 flex-col" :class="{ active: total > 0 }" @click="nextStep">
							<span class="word37">{{L['下一步']}}</span>
						</div>
					</div>
				</template>
				<template v-else>
					<!-- 确认信息 -->
					<template v-for="(item, index) in submitList.data" :key="index">
						<div class="block6 flex-col">
							<div class="section27 flex-col">
								<div class="layer1 flex-row">
									<span class="txt19">{{L['发票']}}</span>
									<span class="word23">{{L['开票金额：']}}{{L['¥']}}{{ item.invoiceAmount }}</span>
								</div>
								<span class="txt20"> {{L['开票主体：']}}{{ item.storeName }}</span>
								<div class="layer2 flex-row">
									<div class="wrap6 flex-col">
										<div class="bd3 flex-row">
											<el-select v-model="item.type" @change="changeType(index)">
												<el-option v-if="item.invoiceSwitch == 1" :label="L['增值税普通发票（电子）']"
													value="11">
												</el-option>
												<el-option :label="L['增值税普通发票（纸质）']" value="12"></el-option>
												<el-option :label="L['增值税专用发票（纸质）']" value="22"></el-option>
											</el-select>
										</div>
									</div>
									<div class="wrap6 flex-col">
										<div class="bd3 flex-row">
											<el-select v-model="item.checkHeader"
												@change="changeHeader(index)" :placeholder="L['请选择发票抬头']">
												<template
													v-for="(headerItem, headerIndex) in invoiceHeader.data"
													:key="headerIndex">
													<el-option
														v-if="item.type == 22 && headerItem.invoiceType == 2"
														:label="headerItem.invoiceTitle"
														:value="headerIndex">
													</el-option>
													<el-option
														v-else-if="item.type != 22 && headerItem.invoiceType == 1"
														:label="headerItem.invoiceTitle"
														:value="headerIndex">
													</el-option>
												</template>
											</el-select>
										</div>
									</div>
								</div>
								<div class="layer3 flex-row" v-if="item.type == 11">
									<span class="info12">{{L['电子邮箱：']}}{{
										item.emailMsg ? item.emailMsg.email : ''
									}}</span>
									<span class="word25"
										@click="openEmail(index, item.emailIndex)">{{L['选择邮箱']}}&gt;&gt;</span>
								</div>
								<div class="layer8 flex-row" v-else>
									<div class="info13 flex_row_start_start" v-if="item.addressMsg">
										<div style="white-space:nowrap;">{{L['寄送信息：']}}</div>

										<div style="line-height:20px;margin-top: -3px;">
											{{ item.addressMsg.memberName }}
											<span>{{ item.addressMsg.telMobile }}</span>
											{{ item.addressMsg.addressAll }}{{
												item.addressMsg.detailAddress
											}}
										</div>

									</div>
									<span class="info14"
										@click="openAddress(index, item.addressIndex)">{{L['选择地址']}}&gt;&gt;</span>
								</div>
								<div class="layer4 flex-col">
									<el-input v-model="item.memberRemark" type="textarea" rows="4"
										show-word-limit maxlength="100"
										:placeholder="L['该备注信息会展示在发票上，如有需要请录入，否则无需录入任何信息。']" resize="none" />
								</div>
								<span class="word27">{{L['建议不要超过25个汉字或50个（数字+字母），否则盖章的时候有可能压到。']}}</span>
							</div>
							<span class="txt23">{{L['发票类型：']}}</span>
							<span class="word28">{{L['发票抬头：']}}</span>
							<span class="txt24">{{L['备注：']}}</span>
						</div>
					</template>
					<div class="flex_row_center_center">
						<button class="block7 flex-col" @click="backStep">
							<span class="txt28">{{L['上一步']}}</span>
						</button>
						<button class="block8 flex-col" @click="finalSubmit">
							<span class="txt28">{{L['确定']}}</span>
						</button>
					</div>
				</template>
			</div>
		</div>

		<!-- 编辑申请数量 start -->
		<el-dialog ref="editApplynum" :title="L['编辑申请数量']" v-model="editDialog" width="440px" top="30vh">
			<div class="edit_main">
				<span>{{L['申请数量：']}}</span>
				<el-input type="number" v-model="applyNum" :placeholder="L['请输入申请数量']" maxlength="9" @input="checkVal" />
			</div>
			<div class="edit_btn">
				<div class="edit_cancle" @click="close_edit">{{L['取消']}}</div>
				<div class="edit_submit" @click="submit_edit">{{L['确定']}}</div>
			</div>
		</el-dialog>
		<!-- 编辑申请数量 end -->

		<!-- 选择邮箱 start -->
		<el-dialog ref="editApplynum" :title="L['选择邮箱']" v-model="emailDialog" width="550px">
			<div class="address_box">
				<template v-for="(emailItem, index) in emailList.data" :key="index">
					<div class="address_item" @click="changeEmail(index)" :class="{ active: emailIndex == index }">
						<span>{{ emailItem.email }}</span>
						<span v-if="emailItem.isDefault == '1'">{{L['默认邮箱']}}</span>
					</div>
				</template>
			</div>
			<div class="edit_btn">
				<div class="edit_cancle" @click="close_email">{{L['取消']}}</div>
				<div class="edit_submit" @click="submit_email">{{L['确定']}}</div>
			</div>
		</el-dialog>
		<!-- 选择邮箱 end -->

		<!-- 选择地址 start -->
		<el-dialog ref="editApplynum" :title="L['选择地址']" v-model="addressDialog" width="550px">
			<div v-if="addressList.data.length">
				<div class="address_box">
					<template v-for="(addressItem, index) in addressList.data" :key="index">
						<div class="address_item" @click="changeAddress(index)"
							:class="{ active: addressIndex == index }">
							<span>{{ addressItem.addressAll }} {{ addressItem.detailAddress }}</span>
							<span v-if="addressItem.isDefault == '1'">{{L['默认地址']}}</span>
						</div>
					</template>
				</div>
				<div class="edit_btn">
					<div class="edit_cancle" @click="close_address">{{L['取消']}}</div>
					<div class="edit_submit" @click="submit_address">{{L['确定']}}</div>
				</div>
			</div>


			<div class="empty_address flex_column_center_center" v-else>
				<img src="@/assets/proEmpty.png" alt="">
				<p>{{L['暂无地址信息']}}</p>
			</div>
		</el-dialog>
		<!-- 选择地址 end -->

	</div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import SldCommonEmpty from '@/components/SldCommonEmpty';
export default {
	name: "FinaceInvoiceAdd",
	components: {
		SldCommonEmpty
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const router = useRouter();
		const step = ref(1);
		const pageData = reactive({
			current: 1,
			pageSize: 10,
			total: 0
		});
		const invoiceSwitch = ref(0); //电子发票开关 0:关  1:开
		const incoiceList = reactive({ data: [] });
		const searchVal = reactive({});
		const selectTime = ref('');
		const checkAllVal = ref(false);
		const addList = reactive({ listDTO: [], ids: [] });
		const total = ref(0);
		const sumMoney = ref(0);
		const editDialog = ref(false); //编辑申请数量弹窗
		const editIndex = ref('');
		const applyNum = ref('');
		const submitList = reactive({ data: [] }); //确认列表数据
		/** 确认发票信息 */
		const invoiceHeader = reactive({ data: [] });
		const addressList = reactive({ data: [] });
		const addressIndex = ref(0);
		const emailList = reactive({ data: [] });
		const emailIndex = ref(0);
		const checkEditIndex = ref(null);  //当前编辑的下标
		const addressDialog = ref(false);
		const emailDialog = ref(false);
		const isSubmit = ref(false);
		const storeList = reactive({ data: [] });
		const storeIndex = ref(0);

		const getStoreList = () => { //获取可开票供应商列表
			proxy
				.$get("v3/business/front/invoice/storeList")
				.then((res) => {
					if (res.state == 200) {
						storeList.data = res.data;
					} else {
						ElMessage.error(res.msg);
					}
				})
		};

		const getList = () => { //获取待开票列表
			let param = {
				current: pageData.current,
				pageSize: pageData.pageSize,
				// storeId: storeList.data[storeIndex.value].storeId
			}

			if (searchVal.productCode) {
				param.productCode = searchVal.productCode
			}
			if (searchVal.orderSn) {
				param.orderSn = searchVal.orderSn
			}
			if (searchVal.purchaseSn) {
				param.purchaseSn = searchVal.purchaseSn
			}
			if (selectTime.value) {
				param.startTime = selectTime.value[0]
				param.endTime = selectTime.value[1]
			}
			proxy
				.$get("v3/business/front/invoice/toInvoiceList", param)
				.then((res) => {
					if (res.state == 200) {
						if (res.data.list.length > 0) {
							let totalNum = 0;
							res.data.list.forEach(item => {
								let idx = addList.ids.findIndex(i => i == item.orderSn)

								if (idx > -1) {
									item.isCheck = true;
									totalNum++;
								} else {
									item.isCheck = false;
								}
								item.isEdit = false;
								item.applyNum = item.canApplyNum;
								item.applyNumEdit = 1;
								item.applyMoney = (Number(item.canApplyNum) * Number(item.goodsPrice)).toFixed(2);
							})
							if (totalNum == res.data.list.length) {
								checkAllVal.value = true;
							} else {
								checkAllVal.value = false;

							}
						}
						incoiceList.data = res.data.list;
						pageData.total = res.data.pagination.total;
					} else {
						ElMessage(res.msg);
					}
				});
		};

		const initSearch = () => { //重置
			searchVal.goodsName = '';
			searchVal.productCode = '';
			searchVal.orderSn = '';
			searchVal.purchaseSn = '';
			searchVal.storeName = '';
			searchVal.startTime = '';
			searchVal.endTime = '';
			selectTime.value = '';
			getList();
		};

		const goBack = () => {
			router.back();
		};

		const checkAll = () => { //切换全选
			checkSelect('all');
		};
		const checkSingle = (tar) => {
			checkSelect('single', tar);
		};
		const checkSelect = (type, tar) => { //处理选中项
			let num = 0;
			let arr = addList.listDTO;
			let idsArr = addList.ids;


			if (type == 'all') {
				incoiceList.data.map(item => { item.isCheck = checkAllVal.value })
				if (checkAllVal.value) {
					idsArr = incoiceList.data.map(i => i.orderSn)
					arr = JSON.parse(JSON.stringify(incoiceList.data))
				} else {
					idsArr = []
					arr = []

				}
			} else {
				let index = idsArr.indexOf(tar.orderSn.toString());

				if (index < 0) {
					idsArr.push(tar.orderSn)
					arr.push(tar)
				} else {
					idsArr.splice(index, 1)
					arr.splice(index, 1)
				}

				if (idsArr.length == incoiceList.data.length) {
					checkAllVal.value = true
				} else {
					checkAllVal.value = false
				}

			}
			total.value = idsArr.length;
			addList.listDTO = arr;
			let sum = addList.listDTO.reduce((total, prev) => total + prev.orderAmount, 0);
			sumMoney.value = sum == 0 ? 0 : sum.toFixed(2);
			addList.ids = idsArr;
		};

		const checkNum = (index) => {
			let reduce = incoiceList.data[index].applyNumEdit % 1;
			if (incoiceList.data[index].applyNumEdit <= 0) {
				incoiceList.data[index].applyNumEdit = 1;
			} else if (incoiceList.data[index].applyNumEdit > incoiceList.data[index].canApplyNum) {
				incoiceList.data[index].applyNumEdit = incoiceList.data[index].canApplyNum;
			} else if (reduce > 0) {
				incoiceList.data[index].applyNumEdit -= reduce;
			}
		};
		const setNum = (index) => {
			incoiceList.data[index].applyNum = incoiceList.data[index].applyNumEdit;
			incoiceList.data[index].applyMoney =
				(Number(incoiceList.data[index].applyNum) * Number(incoiceList.data[index].goodsPrice)).toFixed(2);
			incoiceList.data[index].isEdit = false;

			let idsArr = addList.ids.split('/');
			let updateIndex = idsArr.indexOf(incoiceList.data[index].orderProductId.toString());
			if (updateIndex != -1) {
				addList.listDTO[updateIndex - 1] = incoiceList.data[index];
				let sum = 0;
				addList.listDTO.forEach(item => {
					sum += Number(item.applyMoney);
				})
				sumMoney.value = sum == 0 ? 0 : sum.toFixed(2);
			}
		};
		const editNum = (index) => { //编辑申请数量
			incoiceList.data[index].isEdit = true;
			incoiceList.data[index].applyNumEdit = incoiceList.data[index].applyNum;

			// editDialog.value = true;
			// editIndex.value = index;
			// applyNum.value = incoiceList.data[index].applyNum ? 
			//   incoiceList.data[index].applyNum : ''
		};
		const close_edit = () => {
			editDialog.value = false;
			editIndex.value = '';
			applyNum.value = '';
		};
		const submit_edit = () => {
			if (!applyNum.value) {
				ElMessage.warning(L['请输入申请数量']);
				return;
			} else if (applyNum.value < 1) {
				applyNum.value = '';
				ElMessage.warning(L['请输入正确的数量']);
				return;
			} else if (applyNum.value > incoiceList.data[editIndex.value].canApplyNum) {
				ElMessage.warning(L['申请数量最大为'] + incoiceList.data[editIndex.value].canApplyNum);
				applyNum.value = incoiceList.data[editIndex.value].canApplyNum;
				return;
			}
			incoiceList.data[editIndex.value].applyNum = applyNum.value
			incoiceList.data[editIndex.value].applyMoney = applyNum.value * incoiceList.data[editIndex.value].goodsPrice
			close_edit();
			checkSelect();
		};

		const backStep = () => { //上一步
			if (step.value > 1) {
				step.value -= 1;
			}
			if (step.value == 0) {
				pageData.current = 1;
				pageData.total = 0;
				incoiceList.data = [];
				addList.listDTO = [];
				addList.ids = '/';
				checkAllVal.value = false;
			}
		};

		const nextStep = () => { //下一步

			switch (step.value) {
				case 1: {
					if (total.value == 0) {
						return;
					}
					let param = {
						orderSns: addList.ids.join(',')
					}

					proxy
						.$post('v3/business/front/invoice/toAddInvoice', param)
						.then(res => {
							submitList.data = res.data;
							step.value = 2;
							getInvoiceHeader();
							getAddressList();
							getEmailList();
						})
					break;
				}
			}

		};

		const changeType = (index) => { //切换发票类型
			let flag = true;

			let type = submitList.data[index].type
			let tarArr = invoiceHeader.data.filter(i => i.invoiceType == String(type)[0])
			submitList.data.map(item => {
				if (tarArr.length == 1) {
					flag = false;
					let { invoiceId, invoiceTitle, invoiceTypeValue, titleType, invoiceType } = invoiceHeader.data[0]
					item.checkHeader = invoiceHeader.data.findIndex(i => i.invoiceType == String(type)[0]);
					item.invoiceId = invoiceId;
					item.invoiceTitle = invoiceTitle;
					item.invoiceType = invoiceType
					item.invoiceTypeValue = invoiceTypeValue;
					item.titleType = titleType;
					item.invoiceInfo = JSON.stringify(invoiceHeader.data[0]);
				}
			})



			if (flag) {
				submitList.data[index].checkHeader = '';
				submitList.data[index].invoiceId = '';
				submitList.data[index].invoiceTitle = '';
				submitList.data[index].invoiceTypeValue = '';
				submitList.data[index].titleType = '';
				submitList.data[index].invoiceInfo = '';
				submitList.data[index].invoiceType = '';

			}
		};

		const getInvoiceHeader = () => { //获取发票抬头数据
			proxy
				.$get('v3/member/front/invoiceTitle/list')
				.then(res => {
					invoiceHeader.data = res.data.list;

				})
		};
		const getAddressList = () => { //获取地址列表
			proxy
				.$get("v3/member/front/memberInvoiceAddress/list")
				.then((res) => {
					addressList.data = res.data.list;
					let address = null;
					let addressIndex = 0;
					res.data.list.map((item, index) => {
						if (item.isDefault == '1') {
							address = item;
							addressIndex = index;
						}
					})
					if (!address) {
						address = res.data.list[0];
					}
					submitList.data.map(item => {
						item.addressMsg = address;
						item.addressIndex = addressIndex;
						item.addressInfo = JSON.stringify(address);
					})
				});
		};
		const getEmailList = () => { //获取邮箱列表
			proxy
				.$get("v3/member/front/memberInvoiceEmail/list")
				.then((res) => {
					emailList.data = res.data.list;
					let email = null;
					let emailIndex = 0;
					res.data.list.map((item, index) => {
						if (item.isDefault == '1') {
							email = item;
							emailIndex = index;
						}
					})
					if (res.data.list.length) {
						if (!email) {
							email = res.data.list[0];
						}
						submitList.data.map(item => {
							item.emailMsg = email;
							item.emailIndex = emailIndex;
							item.receiveEmail = email.email;
						})
					}
				});
		};

		/** 选择地址 */
		const openAddress = (index, aIndex) => {
			addressDialog.value = true;
			checkEditIndex.value = index;
			addressIndex.value = aIndex;
		};
		const changeAddress = (index) => {
			addressIndex.value = index;
		};
		const close_address = () => {
			addressDialog.value = false;
			addressIndex.value = '';
			checkEditIndex.value = '';
		};
		const submit_address = () => {
			submitList.data[checkEditIndex.value].addressMsg = addressList.data[addressIndex.value]
			submitList.data[checkEditIndex.value].addressIndex = addressIndex.value
			submitList.data[checkEditIndex.value].addressInfo = JSON.stringify(addressList.data[addressIndex.value])
			close_address();
		};

		/** 选择邮箱 */
		const openEmail = (index, eIndex) => {
			emailDialog.value = true;
			checkEditIndex.value = index;
			emailIndex.value = eIndex;
		};
		const changeEmail = (index) => {
			emailIndex.value = index;
		};
		const close_email = () => {
			emailDialog.value = false;
			emailIndex.value = '';
			checkEditIndex.value = '';
		};
		const submit_email = () => {
			submitList.data[checkEditIndex.value].emailMsg = emailList.data[emailIndex.value]
			submitList.data[checkEditIndex.value].emailIndex = emailIndex.value
			submitList.data[checkEditIndex.value].receiveEmail = emailList.data[emailIndex.value].email
			close_email();
		};

		const changeHeader = (index) => { //切换发票抬头
			submitList.data[index].invoiceId = invoiceHeader.data[submitList.data[index].checkHeader].invoiceId
			submitList.data[index].invoiceTitle = invoiceHeader.data[submitList.data[index].checkHeader].invoiceTitle
			submitList.data[index].invoiceTypeValue = invoiceHeader.data[submitList.data[index].checkHeader].invoiceTypeValue
			submitList.data[index].titleType = invoiceHeader.data[submitList.data[index].checkHeader].titleType
			submitList.data[index].invoiceType = invoiceHeader.data[submitList.data[index].checkHeader].invoiceType
			submitList.data[index].invoiceInfo = JSON.stringify(invoiceHeader.data[submitList.data[index].checkHeader])
		}

		const finalSubmit = () => { //确认提交发票
			if (isSubmit.value) {
				return;
			}
			let flag = false;

			let list = submitList.data.map(item => {

				let obj = { ...item }


				if (!obj.invoiceId) {
					flag = true;
				} else {
					obj.type = Number(String(obj.type)[1])
				}

				return obj
			})



			if (flag) {
				ElMessage.warning(L['请选择发票抬头'])
				return;
			}
			isSubmit.value = true;
			let config = {
				header: 'Content-Type:application/json'
			}
			proxy
				.$post('v3/business/front/invoice/add', list, config)
				.then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						setTimeout(() => {
							router.back();
						}, 1500)
					} else {
						ElMessage.error(res.msg);
						isSubmit.value = false;
					}
				}).catch(() => {
					isSubmit.value = false;
				})
		};

		//页数改变
		const handleCurrentChange = e => {
			pageData.current = Math.floor(e);
			checkAllVal.value = false;
			incoiceList.data = [];
			getList();
		};
		const handleSizeChange = pageSize => {
			pageData.current = 1;
			pageData.pageSize = pageSize;
			checkAllVal.value = false;
			incoiceList.data = [];
			getList();
		};

		const changeStore = (index) => { //切换开票店铺
			if (storeIndex.value != index) {
				storeIndex.value = index;
			}
		}

		onMounted(() => {
			getList()
		});

		return {
			L,
			pageData,
			incoiceList,
			searchVal,
			step,
			selectTime,
			getList,
			initSearch,
			checkNum,
			setNum,
			editNum,
			submit_edit,
			close_edit,
			goBack,
			checkAll,
			checkAllVal,
			editDialog,
			applyNum,
			total,
			sumMoney,
			checkSingle,
			checkSelect,
			backStep,
			nextStep,
			submitList,
			finalSubmit,
			invoiceHeader,
			addressList,
			addressIndex,
			emailList,
			emailIndex,
			checkEditIndex,
			getInvoiceHeader,
			getAddressList,
			getEmailList,
			addressDialog,
			openAddress,
			changeAddress,
			close_address,
			submit_address,
			emailDialog,
			openEmail,
			changeEmail,
			close_email,
			submit_email,
			changeHeader,
			isSubmit,
			handleCurrentChange,
			handleSizeChange,
			storeIndex,
			storeList,
			getStoreList,
			changeStore,
			invoiceSwitch,
			changeType,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "@/style/finace/invoiceAdd.scss";
</style>
<style lang="scss">
.bd30 .group14 .el-input {
	width: 222px;
}

.bd3
/* .bd30 .group14 .el-input__inner {
    width: 222px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  } */

/* .bd30 .group18 .el-range-editor.el-input__inner {
    width: 222px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  } */

.bd30 .group18 .el-date-editor .el-range-separator {
	width: 26px;
	line-height: 24px;
}

/* .bd30 .block6 .el-input__inner {
    width: 260px;
    height: 32px;
    position: absolute;
    left: 0;
    top: 0;
  } */

/* .bd30 .block6 .el-select {
    position: absolute;
    left: 0;
    top: 0;
  } */

.el-select-dropdown__item {
	max-width: 300px;
	word-break: break-all;
}

.bd30 .block6 .el-input--suffix .el-input__inner {
	width: 260px;
	height: 32px;
}

.bd30 .block6 .el-select .el-input {
	width: 260px;
}

.bd30 .block6 .el-input__suffix {
	top: -3px;
}

.bd30 .layer4 .el-textarea {
	width: 660px;
}

.sld_addinvoice_list {
	.edit_main {
		.el-input {
			width: 260px;
		}

		.el-input__inner {
			width: 260px;
			padding-right: 0;
		}
	}

	.el-pagination {
		padding-top: 15px;
	}

	.el-range-editor .el-range-input {
		width: 140px;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.empty_address {

	padding: 40px 0;

	img {
		width: 180px;
		height: 140px;
	}

	p {
		font-size: 13px;
		color: #999;
	}
}
</style>